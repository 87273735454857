import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-deadlock/api.mjs";
import type { ProfileStats } from "@/game-deadlock/models/profileStats.mjs";
import { ProfileStatsValidator } from "@/game-deadlock/models/profileStats.mjs";
import { devWarn } from "@/util/dev.mjs";

const EXPIRY = 1000 * 60 * 10; // 10 minutes

export default function getProfileStats(props: {
  steamId: string;
}): Promise<ProfileStats | undefined> {
  if (!props?.steamId) return;
  try {
    return getData(
      async () => {
        try {
          const response = await fetch(API.getProfileStats(props));
          if (!response.ok) throw new Error();
          return response.json();
        } catch {
          void 0;
        }
      },
      ProfileStatsValidator,
      ["deadlock", "profileStats", props.steamId],
      { expiryTime: EXPIRY },
    );
  } catch (e) {
    devWarn(
      `Deadlock: Failed to fetch player stats with steam id: ${props.steamId}`,
      e,
    );
  }
}
